import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Contact from "../components/contact";
import Hero from "../components/hero";
import AboutCompany from "../components/about-company";
import Equipment from "../components/equipment";
import Certificates from "../components/certificates";

const AboutUs = () => {
  return (
    <Layout>
      <SEO
        title="O nas"
        description="Nasza firma istnieje w kraju od 2008 roku, lecz swe doświadczenie zdobyła na rynku zachodnim, dokładnie w Holandii, gdzie przez bardzo długi czas współpracowaliśmy z firmami produkującymi żywność."
      />
      <Hero
        title="Dowiedz się o nas więcej"
        buttonText="Sprawdź"
        buttonLink="/o-nas#o-firmie"
        useAlternateImage={true}
      />
      <AboutCompany />
      <Equipment />
      <Certificates />
      <Contact />
    </Layout>
  );
};

export default AboutUs;
