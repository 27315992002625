import React from "react";

import SectionTitle from "./section-title";
import ImageGrid from "./image-grid";

import aboutImage1 from "../images/about-1.jpg";
import aboutImage2 from "../images/about-2.jpg";
import aboutImage3 from "../images/about-3.jpg";
import Description from "./description";

const AboutCompany = () => {
  return (
    <section id="o-firmie">
      <Description>
        <SectionTitle title="O nas">
          Nasza firma istnieje w kraju od 2008 roku, lecz swe doświadczenie
          zdobyła na rynku zachodnim, dokładnie w <strong>Holandii</strong>,
          gdzie przez bardzo długi czas współpracowaliśmy z firmami
          produkującymi żywność (m.in. <strong>McCain</strong>,{" "}
          <strong>Farm Frites</strong> czy <strong>Heinz</strong>). Przez lata
          tychże współpracy zdobyliśmy bardzo duże doświadczenie w zakresie
          usług dla przemysłu spożywczego. Dzięki temu jesteśmy dziś w stanie
          zaoferować nasze usługi na najwyższym poziomie poprzez wiedzę i
          doświadczenie zdobyte przez wiele lat współpracy.
        </SectionTitle>
      </Description>
      <ImageGrid images={[aboutImage1, aboutImage2, aboutImage3]} columns={3} />
    </section>
  );
};

export default AboutCompany;
