import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: #ffffff;
  margin: 2rem auto;
  padding: 3rem 5% 2.75rem;

  & > h2 {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const Description = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Description;
