import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Container = styled.section`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    grid-template-columns: repeat(${props => props.columns}, 1fr);
  }
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
`;

const ImageGrid = ({ columns = 1, images }) => {
  const container = useRef(null);

  useEffect(() => {
    gsap.set(container.current.children, {
      opacity: 0,
    });

    ScrollTrigger.batch(container.current.children, {
      once: true,
      start: "top 80%",
      onEnter: targets =>
        gsap.fromTo(
          targets,
          { opacity: 0, y: 50, scale: 0.5 },
          {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 2,
            stagger: 0.7,
            ease: "power2.out",
          }
        ),
    });
  }, []);

  return (
    <Container ref={container} columns={columns}>
      {images.map((image, index) => (
        <Image key={index} src={image} alt="" />
      ))}
    </Container>
  );
};

export default ImageGrid;
