import React from "react";

import SectionTitle from "./section-title";
import ImageGrid from "./image-grid";

import equipmentImage1 from "../images/equipment-1.jpg";
import equipmentImage2 from "../images/equipment-2.jpg";
import equipmentImage3 from "../images/equipment-3.jpg";
import equipmentImage4 from "../images/equipment-4.jpg";
import Description from "./description";

const Equipment = () => {
  return (
    <section>
      <Description>
        <SectionTitle title="Wyposażenie">
          Posiadamy również doskonale wyposażone zaplecze techniczne i personel,
          który jest w stanie świadczyć usługi w terenie za pomocą doskonale
          wyposażonych <strong>aut warsztatowych.</strong>
        </SectionTitle>
      </Description>
      <ImageGrid
        images={[
          equipmentImage1,
          equipmentImage2,
          equipmentImage3,
          equipmentImage4,
        ]}
        columns={4}
      />
    </section>
  );
};

export default Equipment;
