import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import SectionTitle from "./section-title";
import Description from "./description";

import CertificateIcon from "../images/svg/certificate";
import weldingBook from "../images/pdf/welding_book.pdf";
import welderCertificate from "../images/pdf/welder_certificate.pdf";
import dekraCertificate from "../images/pdf/dekra_certificate.pdf";

const CertificatesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.125rem;

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Certificate = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  border-bottom: 2px solid ${props => props.theme.colors.primary};
  padding: 2rem 4rem;
  transition-property: color, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.primary};
    color: #ffffff;

    svg {
      fill: #ffffff;
    }

    p::before {
      background-color: #ffffff;
    }
  }
`;

const Heading = styled.h3`
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0;
`;

const Title = styled.h3`
  font-weight: normal;
  font-size: 1rem;
  margin: 1rem 0 0.75rem;
`;

const Icon = styled.div`
  margin-bottom: 2rem;
  width: 80%;
  max-width: 150px;
`;

const Text = styled.p`
  position: relative;
  margin: 0;
  transition: background-color 0.2s ease;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000000;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    transform: scaleX(0.95);
  }
`;

const Certificates = () => {
  const certificatesContainer = useRef(null);

  useEffect(() => {
    gsap.set(certificatesContainer.current.children, { opacity: 0 });

    ScrollTrigger.batch(certificatesContainer.current.children, {
      once: true,
      start: "top 80%",
      onEnter: targets =>
        gsap.fromTo(
          targets,
          {
            y: 50,
            opacity: 0,
            scale: 0.5,
          },
          {
            y: 0,
            opacity: 1,
            scale: 1,
            stagger: 0.7,
            duration: 3,
            ease: "power2.out",
          }
        ),
    });
  }, []);

  return (
    <section>
      <Description>
        <SectionTitle title="Certyfikacja">
          Posiadamy wszelkie wymagane certyfikaty spawalnicze jak i unijne{" "}
          <b>BHP</b>, czyli <b>VCA</b>, <b>SCC</b> wystawione przez niemiecki
          instytut <b>DEKRA</b>. Pozwala nam to pracować w miejscach wysokiego
          ryzyka takich jak np. rafinerie.
        </SectionTitle>
      </Description>
      <CertificatesContainer ref={certificatesContainer}>
        <Certificate href={weldingBook} target="_blank" rel="noopener">
          <Heading>Certyfikat 1</Heading>
          <Title>Książka spawalnicza</Title>
          <Icon>
            <CertificateIcon />
          </Icon>
          <Text>Kliknij, aby zobaczyć</Text>
        </Certificate>
        <Certificate href={welderCertificate} target="_blank" rel="noopener">
          <Heading>Certyfikat 2</Heading>
          <Title>Świadectwo egzaminu spawacza</Title>
          <Icon>
            <CertificateIcon />
          </Icon>
          <Text>Kliknij, aby zobaczyć</Text>
        </Certificate>
        <Certificate href={dekraCertificate} target="_blank" rel="noopener">
          <Heading>Certyfikat 3</Heading>
          <Title>Certyfikat DEKRA</Title>
          <Icon>
            <CertificateIcon />
          </Icon>
          <Text>Kliknij, aby zobaczyć</Text>
        </Certificate>
      </CertificatesContainer>
    </section>
  );
};

export default Certificates;
